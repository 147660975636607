import "./styles.css";
import * as React from "react";
import { useState, useEffect } from "react";
import firebase from "./firebase";
import AuthScreen from "./AuthScreen";
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  limit
} from "firebase/firestore/lite";
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut
} from "firebase/auth";
import Select from "react-select";

export default function Admin() {
  const [user, setUser] = useState();
  const [discords, setDiscords] = useState();

  const allowedEmails = ["thuddesigns@gmail.com", "cutterdbruce@gmail.com", "chris@byteanalytics.io"];

  const db = getFirestore();
  const alert = useAlert();
  const auth = getAuth();

  const getServers = async () => {
    const servers = await getDocs(collection(db, "servers"));
    setDiscords(servers);
  };

  useEffect(() => {
    getServers();
  }, []);

  const authListener = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  };

  authListener();

  const logout = () => {
    auth.signOut();
  };

  const Form = () => {
    const [timeZone, setTimeZone] = useState("");
    const [token, setToken] = useState("");
    const [twitter, setTwitter] = useState("");
    const [website, setWebsite] = useState("");
    const [majorChannel, setMajorChannel] = useState("");
    const [minorChannel, setMinorChannel] = useState("");
    const [privateDiscord, setPrivateDiscord] = useState("");
    const [selectedDiscord, setSelectedDiscord] = useState();
    console.log(timeZone);
    useEffect(() => {
      const getDatabaseInfo = async () => {
        const discord = await getDoc(doc(db, "servers", selectedDiscord[0]));
        if (discord.data().timeZone) {
          setTimeZone(discord.data().timeZone);
        }
        if (discord.data().token) {
          setToken(discord.data().token);
        }
        if (discord.data().twitter) {
          setTwitter(discord.data().twitter);
        }
        if (discord.data().website) {
          setWebsite(discord.data().website);
        }
        if (discord.data().channels.major) {
          setMajorChannel(discord.data().channels.major);
        }
        if (discord.data().channels.minor) {
          setMinorChannel(discord.data().channels.minor);
        }
        if (discord.data().privateDiscord) {
          setPrivateDiscord(discord.data().privateDiscord);
        }
      };
      if (selectedDiscord) {
        getDatabaseInfo();
      }
    }, [selectedDiscord]);

    const changeTimeZone = async (value) => {
      await updateDoc(doc(db, "servers", selectedDiscord[0]), {
        timeZone: value
      });
      alert.success("Successfully changed!");
    };
    const changeToken = async (value) => {
      await updateDoc(doc(db, "servers", selectedDiscord[0]), {
        token: value
      });
      alert.success("Successfully changed!");
    };
    const changeTwitter = async (value) => {
      await updateDoc(doc(db, "servers", selectedDiscord[0]), {
        twitter: value
      });
      alert.success("Successfully changed!");
    };
    const changeWebsite = async (value) => {
      await updateDoc(doc(db, "servers", selectedDiscord[0]), {
        website: value
      });
      alert.success("Successfully changed!");
    };
    const changeChannels = async (value) => {
      if (majorChannel && minorChannel) {
        await updateDoc(doc(db, "servers", selectedDiscord[0]), {
          channels: { major: value[0], minor: value[1] }
        });
        alert.success("Successfully changed!");
      } else {
        alert.error("Must enter both major and minor");
      }
    };
    const changePrivateDiscord = async (value) => {
      await updateDoc(doc(db, "servers", selectedDiscord[0]), {
        privateDiscord: value
      });
      alert.success("Successfully changed!");
    };
    //search for new discords to add to watchlist
    const SearchBar = () => {
      const styles = {
        control: (styles) => ({
          ...styles,
          cursor: "text",
          borderRadius: "5px",
          minHeight: "30px",
          padding: "0px",
          width: "300px",
          padding: "10px 20px",
          borderColor: "hsl(0deg 0% 50%)"
        }),
        menu: (styles) => ({
          ...styles,
          margin: "0",
          borderRadius: "0",
          backgroundColor: "hsl(0deg 0% 87%)",
          color: "#000"
        }),
        menuList: (styles) => ({
          ...styles,
          maxHeight: "300px"
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isFocused
              ? "#e7e7e7"
              : isSelected
              ? "#e7e7e7"
              : "none",
            ":active": { background: "none !important" },
            color: "#000"
          };
        },
        indicatorsContainer: (styles) => ({ ...styles, display: "none" })
      };
      if (discords) {
        let items = [];
        discords.forEach((discord) => {
          items.push({
            value: discord.id.toString(),
            label: discord.data().name
          });
        });

        return (
          <div>
            <Select
              placeholder={
                !selectedDiscord
                  ? "Search for a discord..."
                  : selectedDiscord[1]
              }
              options={items}
              onChange={(e) => {
                setSelectedDiscord([e.value, e.label]);
              }}
              styles={styles}
            />
          </div>
        );
      } else {
        return null;
      }
    };

    return (
      <div className="formContainerOuter">
        <div className="formContainerInner">
          {!selectedDiscord ? (
            <div className="adminInput">
              <SearchBar />
            </div>
          ) : (
            <>
              <div className="adminInput">
                <SearchBar />
              </div>
              <div className="adminInput">
                <input
                  className="formInput"
                  type="text"
                  alt="Time Zone"
                  placeholder={!timeZone ? "Time Zone" : timeZone}
                  onChange={(e) => setTimeZone(e.target.value)}
                />
                <button
                  className="actionButton"
                  onClick={() => {
                    changeTimeZone(timeZone);
                  }}
                >
                  Set Time Zone
                </button>
              </div>
              <div className="adminInput">
                <input
                  className="formInput"
                  type="text"
                  alt="Token"
                  placeholder={!token ? "Token" : token}
                  onChange={(e) => setToken(e.target.value)}
                />
                <button
                  className="actionButton"
                  onClick={() => {
                    changeToken(token);
                  }}
                >
                  Set Token
                </button>
              </div>
              <div className="adminInput">
                <input
                  className="formInput"
                  type="text"
                  alt="Twitter"
                  placeholder={!twitter ? "Twitter" : twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
                <button
                  className="actionButton"
                  onClick={() => {
                    changeTwitter(twitter);
                  }}
                >
                  Set Twitter
                </button>
              </div>
              <div className="adminInput">
                <input
                  className="formInput"
                  type="text"
                  alt="Website"
                  placeholder={!website ? "Website" : website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
                <button
                  className="actionButton"
                  onClick={() => {
                    changeWebsite(website);
                  }}
                >
                  Set Website
                </button>
              </div>
              <div className="adminInput">
                <div className="channelsInput">
                  <input
                    className="formInput"
                    type="text"
                    alt="Major Channel"
                    placeholder={
                      !majorChannel
                        ? "Major Channel"
                        : `Major Channel: ${majorChannel}`
                    }
                    onChange={(e) => setMajorChannel(e.target.value)}
                  />
                  <input
                    className="formInput"
                    type="text"
                    alt="Minor Channel"
                    placeholder={
                      !minorChannel
                        ? "Minor Channel"
                        : `Minor Channel: ${minorChannel}`
                    }
                    onChange={(e) => setMinorChannel(e.target.value)}
                  />
                </div>
                <button
                  className="actionButton"
                  onClick={() => {
                    changeChannels([majorChannel, minorChannel]);
                  }}
                >
                  Set Channels
                </button>
              </div>
              <div className="adminInput">
                <input
                  className="formInput"
                  type="text"
                  alt="Private Discord"
                  placeholder={
                    !privateDiscord ? "Private Discord" : privateDiscord
                  }
                  onChange={(e) => setPrivateDiscord(e.target.value)}
                />
                <button
                  className="actionButton"
                  onClick={() => {
                    changePrivateDiscord(privateDiscord);
                  }}
                >
                  Set Private Discord
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const HandleLogin = () => {
    if (
      user === null ||
      user === undefined ||
      !allowedEmails.includes(user.email)
    ) {
      return <AuthScreen />;
    } else {
      return (
        <>
          <button className="adminSignOut" onClick={() => logout()}>
            Sign Out
          </button>
          <Form />
        </>
      );
    }
  };

  return (
    <div className="admin">
      <h1 className="logo">BYTE Admin</h1>
      <HandleLogin />
    </div>
  );
}
