import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAPXgEKyArdbOBKTwKLKlbAqYzWNMVy_2c",
  authDomain: "byte-analytics.firebaseapp.com",
  projectId: "byte-analytics",
  storageBucket: "byte-analytics.appspot.com",
  messagingSenderId: "491394982735",
  appId: "1:491394982735:web:d64679bd76fbd0c53e5b3a"
};

const app = initializeApp(firebaseConfig);

export default app;
