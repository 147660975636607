import "./styles.css";
import * as React from "react";
import { useState, useEffect } from "react";
import MousePositionDiv from "./mouse";
import firebase from "./firebase";
import AuthScreen from "./AuthScreen";
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  addDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  limit
} from "firebase/firestore/lite";
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut
} from "firebase/auth";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";

AOS.init({
  once: true
});

export default function Home() {
  //variables
  const [currentScreen, setCurrentScreen] = useState("home");
  const [dashboard, setDashboard] = useState("Discord");
  const [newWatchlist, setNewWatchlist] = useState(false);
  const [discords, setDiscords] = useState();

  //when user logs in, set his id here // DATABASE STUFF
  //user info
  const [user, setUser] = useState();

  const [userWatchlists, setUserWatchlists] = useState([]);

  const [renderWatchlistNum, setRenderWatchlistNum] = useState();

  const [refresh, setRefresh] = useState(true);

  const [unread, setUnread] = useState();

  const db = getFirestore();
  const alert = useAlert();
  const auth = getAuth();

  //read and set discords available from within database // DATABASE STUFF
  const getServers = async () => {
    const servers = await getDocs(collection(db, "servers"));
    setDiscords(servers);
  };

  //DONT DELETE -- USE THIS TO QUERY THE WHOLE ANNOUNCEMENTS SECTION FOR SPECIFIC SERVER AND TYPE
  /*const test = async () => {
    const serversRef = collection(db, "announcements");
    const q = query(
      serversRef,
      where("discord", "==", "KnifeForkSpoon"),
      where("type", "==", "major")
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log("test: " + doc.data().info);
    });
  };
  test();*/

  const authListener = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  };

  authListener();

  const logout = () => {
    auth.signOut();
  };

  const getWatchlists = async () => {
    //get all watchlists for a user
    const watchlistInfo = [];
    const watchlists = collection(db, "watchlists");
    const q = query(watchlists, where("user", "==", user.uid));
    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      // get all server info for that watchlist
      const serversInfo = [];
      for (const server of doc.data().servers) {
        const servers = collection(db, "servers");
        const q2 = query(servers, where("name", "==", server));
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
          serversInfo.push(doc);
        });
      }
      watchlistInfo.push({
        name: doc.data().name,
        servers: serversInfo,
        lastViewed: doc.data().lastViewed,
        id: doc.id
      });
    }
    setUserWatchlists(watchlistInfo);
  };
  // GET ALL DISCORD SERVERS IN THE DATABASE
  useEffect(() => {
    const getDBInfo = async () => {
      await getServers();
    };
    getDBInfo();
  }, []);
  // GET ALL USER WATCHLIST INFO (NAME, SERVERS, DATABASE ID)
  useEffect(() => {
    const getDBInfo = async () => {
      if (user === null || user === undefined) {
        setUserWatchlists([]);
      } else {
        await getWatchlists();
      }
    };
    getDBInfo();
  }, [user, refresh]);

  useEffect(() => {
    //console.log(userWatchlists);

    const getNotifications = async () => {
      // this will get all the dsicords in every watch list a user has, then it will get the last time they checked a watchlist containing them.
      const getUserDiscords = async () => {
        const userDiscords = [];
        userWatchlists.forEach((watchlist) => {
          watchlist.servers.forEach((discord) => {
            const duplicate = userDiscords.find(
              (element) => element[0] === discord.data().name
            );

            if (duplicate === undefined) {
              userDiscords.push([discord.data().name, watchlist.lastViewed]);
            } /*else {
              if (duplicate[1] < watchlist.lastViewed) {
                userDiscords.pop(duplicate[1]);
                userDiscords.push([discord.data().name, watchlist.lastViewed]);
              }
            }*/
          });
        });
        return userDiscords;
      };

      const getunreadAnnouncements = async (userDiscords) => {
        const unreadAnnouncements = [];
        for (const discord of userDiscords) {
          const discordUnread = [];
          const newDate = new Date(discord[1].seconds * 1000);
          const announcementsRef = collection(db, "announcements");
          const q = query(
            announcementsRef,
            where("discord", "==", discord[0])
            //where("discord", "==", discord[0])
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((announcement) => {
            if (announcement.data().time.seconds * 1000 > newDate) {
              discordUnread.push(announcement);
              /*console.log("Discord: " + announcement.data().discord);
              console.log(
                "Time: " + new Date(announcement.data().time.seconds * 1000)
              );
              console.log("Info: " + announcement.data().info);*/
            }
            //console.log(unreadAnnouncement.id, unreadAnnouncement.time);
          });
          unreadAnnouncements.push({
            discord: discord[0],
            announcements: discordUnread
          });
        }
        return unreadAnnouncements;
      };
      if (userWatchlists.length != 0) {
        const userDiscords = await getUserDiscords();
        if (userDiscords) {
          const unreadAnnouncements = await getunreadAnnouncements(
            userDiscords
          );
          if (unreadAnnouncements.length != 0) {
            setUnread(unreadAnnouncements);
          } else {
            setUnread([])
          }
        }
      }
    };
    getNotifications();
    console.log("here")
  }, [userWatchlists]);

  const AddNewWatchlist = () => {
    //write and set selected discords to database // DATABASE STUFF
    const [selectedDiscords, setSelectedDiscords] = useState([]);

    //search for new discords to add to watchlist
    const [searchProject, setSearchProject] = useState(null);
    const [watchlistName, setWatchlistName] = useState(null);
    const bySearchProject = (project, searchProject) => {
      if (searchProject) {
        return project.toLowerCase().includes(searchProject.toLowerCase());
      } else return project;
    };

    const filteredListProject = (discords, searchProject) => {
      let discordsArray = [];
      discords.forEach((item) => {
        discordsArray.push(item.data().name);
      });
      return discordsArray.filter((discord) =>
        bySearchProject(discord, searchProject)
      );
    };

    const addDiscordToArray = (item) => {
      if (selectedDiscords.includes(item)) {
        setSelectedDiscords(
          selectedDiscords.filter((discord) => discord !== item)
        );
      } else {
        setSelectedDiscords((oldArray) => [...oldArray, item]);
      }
    };

    const createWatchlist = async () => {
      if (selectedDiscords.length === 0) {
        alert.error("No discords selected!");
      } else if (watchlistName === null || watchlistName.length === 0) {
        alert.error("Enter watchlist name!");
      } else {
        const docRef = await addDoc(collection(db, "watchlists"), {
          name: watchlistName,
          servers: selectedDiscords,
          user: user.uid,
          lastViewed: new Date(Date.now())
        });
        alert.success("Watchlist added!");
        setNewWatchlist(false);
        await getWatchlists();
      }
    };

    return (
      <div className="newWatchlist">
        <div
          className="hamburgerWrapper"
          onClick={() => setNewWatchlist(false)}
        >
          <div className="hamburger1" />
          <div className="hamburger2" />
        </div>
        <div className="newWatchlistInner">
          <div className="header">
            <div className="watchlistInputs">
              <input
                type="search"
                placeholder="Watchlist name"
                onChange={(e) => setWatchlistName(e.target.value)}
              />
              <input
                type="search"
                placeholder={"Search for a discord"}
                onChange={(e) => setSearchProject(e.target.value)}
              />
            </div>
            <div className="confirmChange">
              <h2>
                {selectedDiscords.length === 0
                  ? "No discords selected"
                  : selectedDiscords.toString()}
              </h2>
              <button onClick={() => createWatchlist()}>Create</button>
            </div>
          </div>
          <div className="selectDiscords">
            {filteredListProject(discords, searchProject).map(
              (discord, index) => (
                <div
                  key={discord + index}
                  onClick={() => addDiscordToArray(discord)}
                >
                  <h1>{discord}</h1>
                  {selectedDiscords.includes(discord) ? (
                    <img
                      className="plusorminus red"
                      src="/images/x.png"
                      alt=""
                    />
                  ) : (
                    <img
                      className="plusorminus"
                      src="/images/plus.png"
                      alt=""
                    />
                  )}
                </div>
              )
            )}
            <h1 className="didntsee">Didn't see your server? Encourage them to invite our bot for a reward!</h1>
          </div>
        </div>
      </div>
    );
  };

  const HomeScreen = () => {
    return (
      <div className="home">
        <div className="socials">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            onClick={() =>
              (window.location = "https://twitter.com/eightbitcrypto")
            }
          >
            <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            onClick={() =>
              (window.location = "https://discord.gg/KSNgqagJ")
            }
          >
            <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            onClick={() =>
              (window.location = "https://medium.com/@chrispertok")
            }
          >
            <path d="M180.5,74.262C80.813,74.262,0,155.633,0,256S80.819,437.738,180.5,437.738,361,356.373,361,256,280.191,74.262,180.5,74.262Zm288.25,10.646c-49.845,0-90.245,76.619-90.245,171.095s40.406,171.1,90.251,171.1,90.251-76.619,90.251-171.1H559C559,161.5,518.6,84.908,468.752,84.908Zm139.506,17.821c-17.526,0-31.735,68.628-31.735,153.274s14.2,153.274,31.735,153.274S640,340.631,640,256C640,171.351,625.785,102.729,608.258,102.729Z" />
          </svg>
        </div>
        {<MousePositionDiv currentScreen={"home"} />}
        <h2
          data-aos="fade">Manage all your discords in one dashboard</h2>
        <div className="buttonsWrapper">
          <button
            className="enter"
            onClick={() => setCurrentScreen("dashboard")}
            data-aos="fade-up-right"
          >
            <h3>Enter Dashboard</h3>
          </button>
          <button className="invite"
            data-aos="fade-up-left">
            <img src="/images/discord.png" alt="" />
            <h3>Invite BYTE to Your Discord</h3>
          </button>
        </div>
      </div>
    );
  };

  const NavBar = () => {
    const [comingSoon, setComingSoon] = useState();
    return (
      <>
        {<MousePositionDiv comingSoon={comingSoon} />}
        <div className="sidebar">
          <div className="innerSidebar">
            <h1 className="logo" onClick={() => setCurrentScreen("backToHome")}>
              <img src="/images/whitelogo.png" alt="" />
            </h1>
            <button
              style={
                dashboard === "Discord" || dashboard === "Watchlist"
                  ? { background: "#0000000f" }
                  : null
              }
            >
              Discord Dashboard
            </button>
            <button
              className="greyedOut"
              onMouseEnter={() => setComingSoon("NFTs")}
              onMouseLeave={() => setComingSoon(null)}
            >
              NFTs
            </button>
            <button
              className="greyedOut"
              onMouseEnter={() => setComingSoon("Portfolio")}
              onMouseLeave={() => setComingSoon(null)}
            >
              Portfolio
            </button>
            <button
              className="greyedOut"
              onMouseEnter={() => setComingSoon("Monitor")}
              onMouseLeave={() => setComingSoon(null)}
            >
              Monitor
            </button>
            <button
              className="greyedOut"
              onMouseEnter={() => setComingSoon("News")}
              onMouseLeave={() => setComingSoon(null)}
            >
              News
            </button>
            <button
              className="greyedOut"
              onMouseEnter={() => setComingSoon("Notes")}
              onMouseLeave={() => setComingSoon(null)}
            >
              Notes
            </button>
            <button
              className="greyedOut"
              onMouseEnter={() => setComingSoon("Friends")}
              onMouseLeave={() => setComingSoon(null)}
            >
              Friends
            </button>
            <button className="greyedOut"
              onMouseEnter={() => setComingSoon("Help")}
              onMouseLeave={() => setComingSoon(null)}>Help</button>
          </div>
          <div className="socials">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              onClick={() =>
                (window.location = "https://twitter.com/eightbitcrypto")
              }
            >
              <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              onClick={() =>
                (window.location = "https://discord.gg/KSNgqagJ")
              }
            >
              <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              onClick={() =>
                (window.location = "https://medium.com/@chrispertok")
              }
            >
              <path d="M180.5,74.262C80.813,74.262,0,155.633,0,256S80.819,437.738,180.5,437.738,361,356.373,361,256,280.191,74.262,180.5,74.262Zm288.25,10.646c-49.845,0-90.245,76.619-90.245,171.095s40.406,171.1,90.251,171.1,90.251-76.619,90.251-171.1H559C559,161.5,518.6,84.908,468.752,84.908Zm139.506,17.821c-17.526,0-31.735,68.628-31.735,153.274s14.2,153.274,31.735,153.274S640,340.631,640,256C640,171.351,625.785,102.729,608.258,102.729Z" />
            </svg>
          </div>
        </div>
      </>
    );
  };

  const DashboardScreen = (name) => {
    const getUnread = () => {
      let unreadHelperArray = [];
      userWatchlists.forEach((watchlist) => {
        let watchlistsArray = [];
        watchlist.servers.forEach((server) => {
          unread.forEach((unreadserver) => {
            if (unreadserver.discord === server.data().name) {
              watchlistsArray.push({
                server: unreadserver.discord,
                announcements: unreadserver.announcements
              });
            }
          });
        });
        unreadHelperArray.push(watchlistsArray);
      });
      return unreadHelperArray;
    };
    const ProfileDropdown = () => {
      const [dropdown, setDropdown] = useState(false);
      if (user) {
        return (
          <div className="profileDropdown">
            <img
              src="/images/profile.png"
              onClick={() => setDropdown(!dropdown)}
              alt=""
            />
            {dropdown ? (
              <div>
                <button>Profile</button>
                <button onClick={() => logout()}>Log Out</button>
              </div>
            ) : null}
          </div>
        );
      } else {
        return null;
      }
    };
    const MainDropdown = () => {
      const [dropdown, setDropdown] = useState(false);
      if (user) {
        return (
          <div className="mainDropdown">
            <div
              className={`buttonWrapper ${dropdown ? "open" : "closed"}`}
              onClick={() => setDropdown(!dropdown)}
            >
              <div className="hamburger"></div>
            </div>
            {dropdown ? (
              <div className="drop">
                <div className="sidebar">
                  <div className="innerSidebar">
                    <input placeholder="Search"></input>
                    <button
                      style={
                        dashboard === "Discord" || dashboard === "Watchlist"
                          ? { background: "#0000000f" }
                          : null
                      }
                    >
                      Discord Dashboard
                    </button>
                    <button className="greyedOut">NFTs</button>
                    <button className="greyedOut">Portfolio</button>
                    <button className="greyedOut">Monitor</button>
                    <button className="greyedOut">News</button>
                    <button className="greyedOut">Notes</button>
                    <button className="greyedOut">Friends</button>
                    <button className="greyedOut">Help</button>
                  </div>
                  <div className="socials">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      onClick={() =>
                        (window.location = "https://twitter.com/eightbitcrypto")
                      }
                    >
                      <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      onClick={() =>
                        (window.location = "https://discord.gg/KSNgqagJ")
                      }
                    >
                      <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      onClick={() =>
                        (window.location = "https://medium.com/@chrispertok")
                      }
                    >
                      <path d="M180.5,74.262C80.813,74.262,0,155.633,0,256S80.819,437.738,180.5,437.738,361,356.373,361,256,280.191,74.262,180.5,74.262Zm288.25,10.646c-49.845,0-90.245,76.619-90.245,171.095s40.406,171.1,90.251,171.1,90.251-76.619,90.251-171.1H559C559,161.5,518.6,84.908,468.752,84.908Zm139.506,17.821c-17.526,0-31.735,68.628-31.735,153.274s14.2,153.274,31.735,153.274S640,340.631,640,256C640,171.351,625.785,102.729,608.258,102.729Z" />
                    </svg>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        );
      } else {
        return null;
      }
    };

    const RenderWatchLists = () => {
      const [unreadHelper, setUnreadHelper] = useState();
      if (userWatchlists === null || userWatchlists === undefined) {
        return (
          <div className="discord">
            <div
              className="watchlist new"
              onClick={() => setNewWatchlist(true)}
            >
              <div />
            </div>
            {newWatchlist ? (
              <div className="outerWatchlist">{AddNewWatchlist()}</div>
            ) : null}
          </div>
        );
      } else {
        if (unread && unreadHelper === undefined) {
          setUnreadHelper(getUnread());
        }
        const getNumber = (index) => {
          let i = 0;
          unreadHelper[index].forEach((item) => {
            i = i + item.announcements.length;
          });
          return i;
        };
        return (
          <div className="discord">
            {userWatchlists.map((info, index) => {
              return (
                <div
                  key={`watchlist${index}`}
                  className="watchlist"
                  onClick={() => {
                    setDashboard("Watchlist");
                    setRenderWatchlistNum(index);
                  }}
                >
                  {unreadHelper ? (
                    getNumber(index) === 0 ? null : (
                      <div className="unread">{getNumber(index)}</div>
                    )
                  ) : null}
                  {/* read watchlist names from database and map for each one // DATABASE STUFF*/}
                  <h1>{info.name}</h1>
                  <div className="watchlistServerListOuter">
                    <div className="watchlistServerList">
                      {info.servers.map((server, ind) => {
                        return (
                          <h2 key={`server${ind}`}>{server.data().name}</h2>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className="watchlist new"
              onClick={() => setNewWatchlist(true)}
            >
              <div />
            </div>
            {newWatchlist ? (
              <div className="outerWatchlist">{AddNewWatchlist()}</div>
            ) : null}
          </div>
        );
      }
    };

    const DeleteWatchlist = ({ currentWatchlist }) => {
      const deleteIt = async () => {
        confirmAlert({
          title: "Confirm to submit",
          message: "Are you sure you want to delete this watchlist?",
          buttons: [
            {
              label: "Yes",
              onClick: async () => {
                await deleteDoc(doc(db, "watchlists", currentWatchlist.id));
                alert.success("Watchlist deleted!");
                setDashboard("Discord");
                await getWatchlists();
              }
            },
            {
              label: "No",
              onClick: () => {}
            }
          ]
        });
      };
      return (
        <>
          <button onClick={() => deleteIt()}>Delete Watchlist</button>
        </>
      );
    };

    const EditWatchlist = ({ currentWatchlist }) => {
      const [editWatchlist, setEditWatchlist] = useState(false);
      const [selectedDiscords, setSelectedDiscords] = useState([]);
      const [searchProject, setSearchProject] = useState(null);
      const [watchlistName, setWatchlistName] = useState(null);
      let discordsArray = [];
      currentWatchlist.servers.forEach((server) => {
        discordsArray.push(server.data().name);
      });
      if (selectedDiscords.length === 0) {
        setSelectedDiscords(discordsArray);
        setWatchlistName(currentWatchlist.name);
      }
      const bySearchProject = (project, searchProject) => {
        if (searchProject) {
          return project.toLowerCase().includes(searchProject.toLowerCase());
        } else return project;
      };

      const filteredListProject = (discords, searchProject) => {
        let discordsArray = [];
        discords.forEach((item) => {
          discordsArray.push(item.data().name);
        });
        return discordsArray.filter((discord) =>
          bySearchProject(discord, searchProject)
        );
      };

      const addDiscordToArray = (item) => {
        if (selectedDiscords.includes(item)) {
          setSelectedDiscords(
            selectedDiscords.filter((discord) => discord !== item)
          );
        } else {
          setSelectedDiscords((oldArray) => [...oldArray, item]);
        }
      };

      const changeWatchlist = async () => {
        if (selectedDiscords.length === 0) {
          alert.error("No discords selected!");
        } else if (
          watchlistName === undefined ||
          watchlistName === null ||
          watchlistName.length === 0
        ) {
          alert.error("Watchlist name is empty!");
        } else {
          const docRef = await updateDoc(
            doc(db, "watchlists", currentWatchlist.id),
            {
              name: watchlistName,
              servers: selectedDiscords
            }
          );
          alert.success("Watchlist changed!");
          setNewWatchlist(false);
          await getWatchlists();
        }
      };
      return (
        <>
          <button onClick={() => setEditWatchlist(true)}>Edit Watchlist</button>
          {editWatchlist ? (
            <div className="outerWatchlist">
              <div className="newWatchlist">
                <div
                  className="hamburgerWrapper"
                  onClick={() => setEditWatchlist(false)}
                >
                  <div className="hamburger1" />
                  <div className="hamburger2" />
                </div>
                <div className="newWatchlistInner">
                  <div className="header">
                    <div className="watchlistInputs">
                      <input
                        type="search"
                        placeholder="Watchlist name"
                        value={watchlistName}
                        onChange={(e) => setWatchlistName(e.target.value)}
                      />
                      <input
                        type="search"
                        placeholder={"Search for a discord"}
                        onChange={(e) => setSearchProject(e.target.value)}
                      />
                    </div>
                    <div className="confirmChange">
                      <h2>
                        {selectedDiscords.length === 0
                          ? "No discords selected"
                          : selectedDiscords.toString()}
                      </h2>
                      <button onClick={() => changeWatchlist()}>Change</button>
                    </div>
                  </div>
                  <div className="selectDiscords">
                    {filteredListProject(discords, searchProject).map(
                      (discord, index) => (
                        <div
                          key={"discord" + index}
                          onClick={() => addDiscordToArray(discord)}
                        >
                          <h1>{discord}</h1>
                          {selectedDiscords.includes(discord) ? (
                            <img
                              className="plusorminus red"
                              src="/images/x.png"
                              alt=""
                            />
                          ) : (
                            <img
                              className="plusorminus"
                              src="/images/plus.png"
                              alt=""
                            />
                          )}
                        </div>
                      )
                    )}
                    <h1 className="didntsee">Didn't see your server? Encourage them to invite our bot for a reward!</h1>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      );
    };

    const updateWatchlistTimestamp = async () => {
      if (user === null || user === undefined || userWatchlists.length === 0) {
        console.log("No user");
      } else {
        const docRef = doc(
          db,
          "watchlists",
          userWatchlists[renderWatchlistNum].id
        );
        await updateDoc(
          doc(db, "watchlists", userWatchlists[renderWatchlistNum].id),
          {
            lastViewed: new Date(Date.now())
          }
        );
      }
    };
    if (renderWatchlistNum != undefined) {
      updateWatchlistTimestamp();
    }
    //----------------------------------- REGULAR EXPRESSIONS -----------------------------------//
    function isImage(url) {
      return /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    }
    function isVideo(url) {
      return /^https?:\/\/.+\.(mp4|mov)$/.test(url);
    }
    function urlify(text) {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.replace(urlRegex, function (url) {
        /*const removeEndOfImage = url.split("?width=")[0];
        if (isImage(removeEndOfImage) === true) {
          return '<img src="' + removeEndOfImage + '" alt="" />';
        } else {*/
          return '<a href="' + url + '">' + url + "</a>";
        //}
      });
    }
  
    function getDynamicTime1(text) {
      var urlRegex = /[<t:]{3}(.*?)[:R>]{3}/g;
      return text.replace(urlRegex, function (url) {
        const getRidOfNonText = url.split("<t:")[1];
        const getRidOfNonText2 = getRidOfNonText.split(":R>")[0];
        const time = getRidOfNonText2 * 1000;
        const minutesAgo = (Date.now() - time) / 100000;
        return Math.round(minutesAgo) + " minutes ago";
      });
    }
    function getDynamicTime2(text) {
      var urlRegex = /[<t:]{3}(.*?)[:t>]{3}/g;
      return text.replace(urlRegex, function (url) {
        const getRidOfNonText = url.split("<t:")[1];
        const getRidOfNonText2 = getRidOfNonText.split(":t>")[0];
        const time = new Date(getRidOfNonText2 * 1000);
        return ('<b>' + 
          time.toLocaleString("en-US", {
            timeZone: "UTC"
          }) + " UTC" + '</b>'
        );
      });
    }
    function getDynamicTime3(text) {
      var urlRegex = /[<t:]{3}(.*?)[:T>]{3}/g;
      return text.replace(urlRegex, function (url) {
        const getRidOfNonText = url.split("<t:")[1];
        const getRidOfNonText2 = getRidOfNonText.split(":T>")[0];
        const time = new Date(getRidOfNonText2 * 1000);
        return ('<b>' + 
          time.toLocaleString("en-US", {
            timeZone: "UTC"
          }) + " UTC" + '</b>'
        );
      });
    }
    function getDynamicTime4(text) {
      var urlRegex = /[<t:]{3}(.*?)[:d>]{3}/g;
      return text.replace(urlRegex, function (url) {
        const getRidOfNonText = url.split("<t:")[1];
        const getRidOfNonText2 = getRidOfNonText.split(":d>")[0];
        const time = new Date(getRidOfNonText2 * 1000);
        console.log(getRidOfNonText2);
        return ('<b>' + 
          time.toLocaleString("en-US", {
            timeZone: "UTC"
          }) + " UTC" + '</b>'
        );
      });
    }
    function getDynamicTime5(text) {
      var urlRegex = /[<t:]{3}(.*?)[:D>]{3}/g;
      return text.replace(urlRegex, function (url) {
        const getRidOfNonText = url.split("<t:")[1];
        const getRidOfNonText2 = getRidOfNonText.split(":D>")[0];
        const time = new Date(getRidOfNonText2 * 1000);
        return ('<b>' + 
          time.toLocaleString("en-US", {
            timeZone: "UTC"
          }) + " UTC" + '</b>'
        );
      });
    }
    function getDynamicTime6(text) {
      var urlRegex = /[<t:]{3}(.*?)[:f *>]{5}/g;
      return text.replace(urlRegex, function (url) {
        const getRidOfNonText = url.split("<t:")[1];
        const getRidOfNonText2 = getRidOfNonText.split(":f *>")[0];
        const time = new Date(getRidOfNonText2 * 1000);
        return ('<b>' + 
          time.toLocaleString("en-US", {
            timeZone: "UTC"
          }) + " UTC" + '</b>'
        );
      });
    }
    function getDynamicTime7(text) {
      var urlRegex = /[<t:]{3}(.*?)[:F>]{3}/g;
      return text.replace(urlRegex, function (url) {
        const getRidOfNonText = url.split("<t:")[1];
        const getRidOfNonText2 = getRidOfNonText.split(":F>")[0];
        const time = new Date(getRidOfNonText2 * 1000);
        return ('<b>' + 
          time.toLocaleString("en-US", {
            timeZone: "UTC"
          }) + " UTC" + '</b>'
        );
      });
    }
  
    function getDynamicTime8(text) {
      var urlRegex = /[<t:]{3}(.*?)[>]{1}/g;
      return text.replace(urlRegex, function (url) {
        const getRidOfNonText = url.split("<t:")[1];
        const getRidOfNonText2 = getRidOfNonText.split(">")[0];
        const time = new Date(getRidOfNonText2 * 1000);
        return ('<b>' + 
          time.toLocaleString("en-US", {
            timeZone: "UTC"
          }) + " UTC" + '</b>'
        );
      });
    }
    function boldify(text) {
      var urlRegex = /[**]{2}(.*?)[**]{2}/gi;
      return text.replace(urlRegex, function (bold) {
        const getRidOfNonText = bold.split("**");
        return "<b>" + getRidOfNonText[1] + "</b>";
      });
    }
    function italicize(text) {
      var urlRegex = /[*]{1}(.*?)[*]{1}/gi;
      return text.replace(urlRegex, function (bold) {
        const getRidOfNonText = bold.split("*");
        return "<i>" + getRidOfNonText[1] + "</i>";
      });
    }
    function strikeThrough(text) {
      var urlRegex = /[~~]{2}(.*?)[~~]{2}/gi;
      return text.replace(urlRegex, function (bold) {
        const getRidOfNonText = bold.split("~~");
        return "<s>" + getRidOfNonText[1] + "</s>";
      });
    }
    //----------------------------------- END REGULAR EXPRESSIONS -----------------------------------//

    const RenderServerLists = () =>
      userWatchlists[renderWatchlistNum].servers.map(function MapIt(
        server,
        index
      ) {
        const [currentOption, setCurrentOption] = useState("major");
        const [enlargeIMG, setEnlargeIMG] = useState();
        const [loading, setLoading] = useState(true);
        const [majorAnnouncements, setMajorAnnouncements] = useState();
        const [minorAnnouncements, setMinorAnnouncements] = useState();
        const [unreadHelper, setUnreadHelper] = useState();

        if (unread && unreadHelper === undefined) {
          setUnreadHelper(getUnread());
        }

        const getNumber = (index, type) => {
          let i = 0;
          if (unreadHelper[renderWatchlistNum][index] != undefined) {
            unreadHelper[renderWatchlistNum][index].announcements.forEach(
              (item) => {
                if (item.data().type === type) {
                  i++;
                }
              }
            );
          }
          return i;
        };
        const FilterMajorAnnouncements = () => {
          let cutoffDate = new Date(Date.now() - 2629743000);
          let announcementsArray = [];
          let unreadOnes = [];
          if (unreadHelper[renderWatchlistNum][index] != undefined) {
            unreadHelper[renderWatchlistNum][index].announcements.forEach(
              (id) => {
                unreadOnes.push(id.id);
              }
            );
          }
          majorAnnouncements.forEach((announcement, ind) => {
            if (announcement.data().time.seconds * 1000 > cutoffDate) {
              if (unreadOnes.includes(announcement.id)) {
                announcementsArray.push({
                  announcement: announcement.data().info,
                  date: announcement.data().time.seconds * 1000,
                  attachments: announcement.data().attachments,
                  read: false
                });
              } else {
                announcementsArray.push({
                  announcement: announcement.data().info,
                  date: announcement.data().time.seconds * 1000,
                  attachments: announcement.data().attachments,
                  read: true
                });
              }
            } else {
              return null;
            }
          });
          announcementsArray.sort(function (x, y) {
            return y.date - x.date;
          });
          //console.log(announcementsArray);
          return announcementsArray;
        };
        const FilterMinorAnnouncements = () => {
          let cutoffDate = new Date(Date.now() - 2629743000);
          let announcementsArray = [];
          let unreadOnes = [];
          if (unreadHelper[renderWatchlistNum][index] != undefined) {
            unreadHelper[renderWatchlistNum][index].announcements.forEach(
              (id) => {
                unreadOnes.push(id.id);
              }
            );
          }
          minorAnnouncements.forEach((announcement, ind) => {
            if (announcement.data().time.seconds * 1000 > cutoffDate) {
              if (unreadOnes.includes(announcement.id)) {
                announcementsArray.push({
                  announcement: announcement.data().info,
                  date: announcement.data().time.seconds * 1000,
                  attachments: announcement.data().attachments,
                  read: false
                });
              } else {
                announcementsArray.push({
                  announcement: announcement.data().info,
                  date: announcement.data().time.seconds * 1000,
                  attachments: announcement.data().attachments,
                  read: true
                });
              }
            } else {
              return null;
            }
          });
          announcementsArray.sort(function (x, y) {
            return y.date - x.date;
          });
          //console.log(announcementsArray);
          return announcementsArray;
        };

        useEffect(() => {
          getDBInfo();
        }, []);
        useEffect(() => {
          getDBInfo();
          setUnreadHelper(getUnread());
        }, [refresh]);

        const getDBInfo = async () => {
          const getAnnouncements = async () => {
            const getMajorAnnouncements = async () => {
              const serversRef = collection(db, "announcements");
              const q = query(
                serversRef,
                where("discord", "==", server.data().name),
                where("type", "==", "major")
              );
              const querySnapshot = await getDocs(q);

              return querySnapshot;
            };

            const getMinorAnnouncements = async () => {
              const serversRef = collection(db, "announcements");
              const q = query(
                serversRef,
                where("discord", "==", server.data().name),
                where("type", "==", "minor")
              );
              const querySnapshot = await getDocs(q);

              return querySnapshot;
            };

            const MajorAnnouncements = await getMajorAnnouncements();

            const MinorAnnouncements = await getMinorAnnouncements();

            return [MajorAnnouncements, MinorAnnouncements];
          };

          const gettingAnnouncements = await getAnnouncements();

          if (
            gettingAnnouncements[0] != null &&
            gettingAnnouncements[1] != null
          ) {
            setMajorAnnouncements(gettingAnnouncements[0]);
            setMinorAnnouncements(gettingAnnouncements[1]);
            setLoading(false);
          }
        };
        return (
          <>
            <div className="individualWatchlist" key={`key${index}`}>
              <div className="header">
                <h1>{server.data().name}</h1>
                <div className="announcementsTitle">
                  <h2 style={{ width: "100%", textAlign: "center" }}>
                    {currentOption === "major"
                      ? "Major Announcements"
                      : currentOption === "minor"
                      ? "Minor Announcements"
                      : currentOption === "info"
                      ? "Links and Info"
                      : null}
                  </h2>
                </div>
              </div>
              <div className="announcements">
                <div className="leftColumn">
                  <div className="discordLogo">
                    <img
                      src={server.data().image}
                      alt=""
                    />
                  </div>
                  <div
                    className="announcementIcon"
                    style={
                      currentOption === "major"
                        ? { background: "#00000026" }
                        : null
                    }
                    onClick={() => {
                      setCurrentOption("major");
                    }}
                  >
                    {unreadHelper ? (
                      getNumber(index, "major") === 0 ? null : (
                        <div className="unread">{getNumber(index, "major")}</div>
                      )
                    ) : null}
                    <img src="/images/major.png" alt="" />
                  </div>
                  <div
                    className="announcementIcon"
                    style={
                      currentOption === "minor"
                        ? { background: "#00000026" }
                        : null
                    }
                    onClick={() => {
                      setCurrentOption("minor");
                    }}
                  >
                    {unreadHelper ? (
                      getNumber(index, "minor") === 0 ? null : (
                        <div className="unread">{getNumber(index, "minor")}</div>
                      )
                    ) : null}
                    <img src="/images/minor.png" alt="" />
                  </div>
                  <div
                    className="announcementIcon"
                    style={
                      currentOption === "info"
                        ? { background: "#00000026" }
                        : null
                    }
                    onClick={() => {
                      setCurrentOption("info");
                    }}
                  >
                    <img src="/images/info.png" alt="" />
                  </div>
                </div>
                {currentOption === "major" ? (
                  <div className="subAnnouncementsOuter">
                    <div className="subAnnouncements">
                      {loading ? (
                        <div className="loading">
                          <img
                            src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
                            alt=""
                          />
                        </div>
                      ) : (
                        FilterMajorAnnouncements().map((announcement, ind) => {
                          const getTime1 = getDynamicTime1(announcement.announcement);
                          const getTime2 = getDynamicTime2(getTime1);
                          const getTime3 = getDynamicTime3(getTime2);
                          const getTime4 = getDynamicTime4(getTime3);
                          const getTime5 = getDynamicTime5(getTime4);
                          const getTime6 = getDynamicTime6(getTime5);
                          const getTime7 = getDynamicTime7(getTime6);
                          const getTime8 = getDynamicTime8(getTime7);
                          const strikedText = strikeThrough(getTime8);
                          const boldedText = boldify(strikedText);
                          const italicsText = italicize(boldedText);
                          const urlText = urlify(italicsText);
                          const formattedURLText = urlText.replace(/\n/g, "<br />");

                          return (
                            <div key={`major${ind}`} className="announcement">
                              <div className="announcementText"
                                style={
                                  announcement.read === false
                                    ? { fontWeight: "600" }
                                    : { fontWeight: "400" }
                                } dangerouslySetInnerHTML={{ __html: formattedURLText }} />
                                {announcement.attachments ?
                                  announcement.attachments.map((url, index) => {
                                    if (isVideo(url) === true) {
                                      return <video onClick={() => setEnlargeIMG(url)} key={`url${index}`} src={url} controls />
                                    } else {
                                      return <img onClick={() => setEnlargeIMG(url)} key={`url${index}`} src={url} />
                                    }
                                  })
                                  : null
                                }
                              <h4>
                                {new Date(announcement.date).toDateString()}
                              </h4>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                ) : currentOption === "minor" ? (
                  <div className="subAnnouncementsOuter">
                    <div className="subAnnouncements">
                      {loading ? (
                        <div className="loading">
                          <img
                            src="https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif"
                            alt=""
                          />
                        </div>
                      ) : (
                        FilterMinorAnnouncements().map((announcement, ind) => {
                          const getTime1 = getDynamicTime1(announcement.announcement);
                          const getTime2 = getDynamicTime2(getTime1);
                          const getTime3 = getDynamicTime3(getTime2);
                          const getTime4 = getDynamicTime4(getTime3);
                          const getTime5 = getDynamicTime5(getTime4);
                          const getTime6 = getDynamicTime6(getTime5);
                          const getTime7 = getDynamicTime7(getTime6);
                          const getTime8 = getDynamicTime8(getTime7);
                          const strikedText = strikeThrough(getTime8);
                          const boldedText = boldify(strikedText);
                          const italicsText = italicize(boldedText);
                          const urlText = urlify(italicsText);
                          const formattedURLText = urlText.replace(/\n/g, "<br />");

                          return (
                            <div key={`minor${ind}`} className="announcement">
                              <div className="announcementText"
                                style={
                                  announcement.read === false
                                    ? { fontWeight: "600" }
                                    : { fontWeight: "400" }
                                } dangerouslySetInnerHTML={{ __html: formattedURLText }} />
                                {announcement.attachments ?
                                  announcement.attachments.map((url, index) => {
                                    if (isVideo(url) === true) {
                                      return <video onClick={() => setEnlargeIMG(url)} key={`url${index}`} src={url} controls />
                                    } else {
                                      return <img onClick={() => setEnlargeIMG(url)} key={`url${index}`} src={url} />
                                    }
                                  })
                                  : null
                                }
                              <h4>
                                {new Date(announcement.date).toDateString()}
                              </h4>
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                ) : currentOption === "info" ? (
                  <div className="subAnnouncementsOuter">
                    <div className="subAnnouncements">
                      <h3 className="announcement" style={{ fontWeight: "400" }}>
                        <b>Name:</b> {server.data().name}
                      </h3>
                      <h3 className="announcement" style={{ fontWeight: "400" }}>
                        <b>Token:</b> {server.data().token}
                      </h3>
                      <h3 className="announcement" style={{ fontWeight: "400" }}>
                        <b>Time Zone:</b> {server.data().timeZone}
                      </h3>
                      <h3 className="announcement" style={{ fontWeight: "400" }}>
                        <b>Discord:</b> <a href={server.data().discord} target="_blank">{server.data().discord}</a>
                      </h3>
                      <h3 className="announcement" style={{ fontWeight: "400" }}>
                        <b>Twitter:</b> <a href={server.data().twitter} target="_blank">{server.data().twitter}</a>
                      </h3>
                      <h3 className="announcement" style={{ fontWeight: "400" }}>
                        <b>Website:</b> <a href={server.data().website} target="_blank">{server.data().website}</a>
                      </h3>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {enlargeIMG != undefined || enlargeIMG != null ?
              <div className="enlarge"
              onClick={() => setEnlargeIMG()}>
                <div
                  className="hamburgerWrapper"
                  onClick={() => setEnlargeIMG()}
                >
                  <div className="hamburger1" />
                  <div className="hamburger2" />
                </div>
                
                {isVideo(enlargeIMG) === true ? 
                  <video src={enlargeIMG} controls />
                  :
                  <img src={enlargeIMG} alt="" />
                }
              </div>
            : null
            }
          </>
        );
      });
    return (
      <div className={name}>
        <NavBar />
        <div className="mainContent">
          <div className="navbar">
            <h1 className="logo" onClick={() => setCurrentScreen("backToHome")}>
              <img src="/images/whitelogo.png" alt="" />
            </h1>
            <div className="nameAndBack">
              <img
                src="/images/backbutton.png"
                alt=""
                onClick={() =>
                  dashboard === "Discord"
                    ? setCurrentScreen("backToHome")
                    : dashboard === "Watchlist"
                    ? (setDashboard("Discord"),setRefresh(!refresh))
                    : null
                }
              />
              <h1>
                {user === null || user === undefined
                  ? null
                  : dashboard === "Discord"
                  ? "Watchlists"
                  : dashboard === "Watchlist"
                  ? userWatchlists[renderWatchlistNum].name
                  : null}
              </h1>
            </div>
            <div>
              <input className="navSearch" placeholder="Search"></input>
              <ProfileDropdown />
              <MainDropdown />
            </div>
          </div>
          <div className="nameAndBack">
            <img
              src="/images/backbutton.png"
              alt=""
              onClick={() => {
                if (dashboard === "Discord") {
                  setCurrentScreen("backToHome");
                } else if (dashboard === "Watchlist") {
                  setDashboard("Discord");
                  setRefresh(!refresh)
                  setRenderWatchlistNum();
                } else {
                  null;
                }
              }}
            />
            <h1>
              {user === null || user === undefined
                ? null
                : dashboard === "Discord"
                ? "Watchlists"
                : dashboard === "Watchlist"
                ? userWatchlists[renderWatchlistNum].name
                : null}
            </h1>
          </div>
          {user === null || user === undefined ? (
            <AuthScreen db={db} />
          ) : (
            <>
              {dashboard === "Discord" ? (
                <RenderWatchLists />
              ) : "Watchlist" ? (
                <div className="watchlistScreen">
                  <div className="edit-delete">
                    <div className="edit-deleteInner">
                      <div className="pulsating" />
                      <h4>Real-Time Data</h4>
                    </div>
                    <div className="edit-deleteInner">
                      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 492.883 492.883" onClick={() => setRefresh(!refresh)}>
                          <path d="M122.941,374.241c-20.1-18.1-34.6-39.8-44.1-63.1c-25.2-61.8-13.4-135.3,35.8-186l45.4,45.4c2.5,2.5,7,0.7,7.6-3
                            l24.8-162.3c0.4-2.7-1.9-5-4.6-4.6l-162.4,24.8c-3.7,0.6-5.5,5.1-3,7.6l45.5,45.5c-75.1,76.8-87.9,192-38.6,282
                            c14.8,27.1,35.3,51.9,61.4,72.7c44.4,35.3,99,52.2,153.2,51.1l10.2-66.7C207.441,421.641,159.441,407.241,122.941,374.241z"/>
                          <path d="M424.941,414.341c75.1-76.8,87.9-192,38.6-282c-14.8-27.1-35.3-51.9-61.4-72.7c-44.4-35.3-99-52.2-153.2-51.1l-10.2,66.7
                            c46.6-4,94.7,10.4,131.2,43.4c20.1,18.1,34.6,39.8,44.1,63.1c25.2,61.8,13.4,135.3-35.8,186l-45.4-45.4c-2.5-2.5-7-0.7-7.6,3
                            l-24.8,162.3c-0.4,2.7,1.9,5,4.6,4.6l162.4-24.8c3.7-0.6,5.4-5.1,3-7.6L424.941,414.341z"/>
                      </svg>
                      <EditWatchlist
                        currentWatchlist={userWatchlists[renderWatchlistNum]}
                      />
                      <DeleteWatchlist
                        currentWatchlist={userWatchlists[renderWatchlistNum]}
                      />
                    </div>
                  </div>
                  {/* read individual watchlist from database and map their watchlist name, major announcements, and subannouncements  // DATABASE STUFF*/}
                  <RenderServerLists />
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    );
  };

  const HandleScreens = () => {
    const [display, setDisplay] = useState();
    if (currentScreen === "dashboard") {
      setTimeout(() => {
        setDisplay("dashboard");
      }, 500);
      if (display === "dashboard") {
        return <>{DashboardScreen("dashboard")}</>;
      } else {
        return (
          <>
            {HomeScreen()}
            {DashboardScreen("dashboardLeft")}
          </>
        );
      }
    } else if (currentScreen === "backToHome") {
      setTimeout(() => {
        setDisplay("home");
      }, 500);
      if (display === "home") {
        return <>{HomeScreen()}</>;
      } else {
        return (
          <>
            {HomeScreen()}
            {DashboardScreen("dashboardRight")}
          </>
        );
      }
    } else {
      return <>{HomeScreen()}</>;
    }
  };

  return <div>{HandleScreens()}</div>;
}
