import { useState } from "react";

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth";

import {
  useCollection,
  getFirestore,
  collection,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  query
} from "firebase/firestore/lite";

import { useAlert } from "react-alert";

const AuthScreen = ({ db }) => {
  const auth = getAuth();
  const alert = useAlert();

  const RenderLoginScreen = ({
    email,
    setEmail,
    password,
    setPassword,
    view,
    setView
  }) => {
    const signIn = async () => {
      await signInWithEmailAndPassword(auth, email, password).catch((error) => {
        if (error.code === "auth/invalid-email") {
          alert.error("No account found for this email!");
        } else if (error.code === "auth/wrong-password") {
          alert.error("Wrong password!");
        } else {
          alert.error("Invalid credentials!");
        }
        console.log(error.code);
        setEmail("");
        setPassword("");
      });
    };

    const buttonStyle = (button) => {
      if (button === "login") {
        return "toggledButton login";
      }

      if (button === "signUp") {
        return "untoggledButton signup";
      }
    };

    return (
      <div className="formContainerOuter">
        <div className="formContainerInner">
          <div className="formContainerFields">
            <div className="buttonContainer">
              <button
                className={buttonStyle("login")}
                onClick={() => setView("login")}
              >
                Login
              </button>
              <button
                className={buttonStyle("signUp")}
                onClick={() => setView("signUp")}
              >
                Sign up
              </button>
            </div>
            <input
              className="formInput"
              type="email"
              alt="Email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="formInput"
              type="password"
              alt="Password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              className="forgotPassword"
              onClick={() => setView("forgotPassword")}
            >
              Forgot Password?
            </button>
            <button className="actionButton" onClick={() => signIn()}>
              Login
            </button>
          </div>
        </div>
      </div>
    );
  };

  const RenderSignupScreen = ({
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    view,
    setView
  }) => {
    const signup = async () => {
      const validatePassword = () => {
        if (password === confirmPassword) {
          createAccoount();
        }

        if (password !== confirmPassword) {
          alert.error("Passwords do not match!");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
        }
      };

      const createAccoount = async () => {
        await createUserWithEmailAndPassword(auth, email, password)
          .then(async (res) => {
            await setDoc(doc(db, "users", res._tokenResponse.localId), {
              email: res._tokenResponse.email,
              verified: false
            });
          })
          .catch((error) => {
            if (error.code === "auth/weak-password") {
              alert.error("Password is too weak!");
            } else if (error.code === "auth/email-already-in-use") {
              alert.error("This email is already in use!");
            } else if (error.code === "auth/invalid-email ") {
              alert.error("Invalid email!");
            } else {
              alert.error("Invalid credentials!");
            }
            console.log(error.code);
            setEmail("");
            setPassword("");
            setConfirmPassword("");
          });
      };

      validatePassword();
    };

    const buttonStyle = (button) => {
      if (button === "login") {
        return "untoggledButton login";
      }

      if (button === "signUp") {
        return "toggledButton signup";
      }
    };

    return (
      <div className="formContainerOuter">
        <div className="formContainerInner">
          <div className="formContainerFields">
            <div className="buttonContainer">
              <button
                className={buttonStyle("login")}
                onClick={() => setView("login")}
              >
                Login
              </button>
              <button
                className={buttonStyle("signUp")}
                onClick={() => setView("signUp")}
              >
                Sign up
              </button>
            </div>
            <input
              className="formInput"
              type="email"
              alt="Email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="formInput"
              type="password"
              alt="Password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              className="formInput"
              type="password"
              alt="confirmPassword"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button className="actionButton" onClick={() => signup()}>
              Create Account
            </button>
          </div>
        </div>
      </div>
    );
  };

  const RenderForgotPasswordScreen = ({ email, setEmail, setView }) => {
    const resetEmail = async () => {
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          alert.success("Password rest link sent to email!");
          setEmail("");
          setView("login");
        })
        .catch((error) => {
          alert.error("Could not reset password for that email!");
          setEmail("");
          console.log(error);
        });
    };

    return (
      <div className="formContainerOuter">
        <div className="formContainerInner">
          <div className="formContainerFields">
            <input
              className="formInput"
              type="email"
              alt="Email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button className="actionButton" onClick={() => resetEmail()}>
              Reset Your Password
            </button>
            <h1 className="or">- OR -</h1>
            <button className="actionButton" onClick={() => setView("login")}>
              Back to Login
            </button>
          </div>
        </div>
      </div>
    );
  };

  const RenderForm = () => {
    const [view, setView] = useState("login");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    if (view === "login") {
      return (
        <>
          <RenderLoginScreen
            email={email}
            password={password}
            view={view}
            setEmail={(e) => setEmail(e)}
            setPassword={(e) => setPassword(e)}
            setView={(e) => setView(e)}
          />
        </>
      );
    }

    if (view === "signUp") {
      return (
        <>
          <RenderSignupScreen
            email={email}
            password={password}
            confirmPassword={confirmPassword}
            view={view}
            setEmail={(e) => setEmail(e)}
            setPassword={(e) => setPassword(e)}
            setConfirmPassword={(e) => setConfirmPassword(e)}
            setView={(e) => setView(e)}
          />
        </>
      );
    }

    if (view === "forgotPassword") {
      return (
        <>
          <RenderForgotPasswordScreen
            email={email}
            setEmail={(e) => setEmail(e)}
            setView={(e) => setView(e)}
          />
        </>
      );
    }
  };

  return (
    <>
      <RenderForm />
    </>
  );
};

export default AuthScreen;
