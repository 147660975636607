import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import App from "./App";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER
};

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <Provider template={AlertTemplate} {...options}>
      <App />
    </Provider>
  </StrictMode>,
  rootElement
);
