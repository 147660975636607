import { useEffect, useState } from "react";
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";

AOS.init({
  once: true
});

const MousePositionDiv = ({ comingSoon, currentScreen }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener("mousemove", setFromEvent);

    return () => {
      window.removeEventListener("mousemove", setFromEvent);
    };
  }, []);
  const comingSoonStyles = {
    position: "fixed",
    zIndex: "1",
    pointerEvents: "none",
    fontSize: "12px",
    fontWeight: "400",
    top: `${position.y + 15}px`,
    left: `${position.x + 15}px`,
    background: "linear-gradient(45deg, #12ab8c, #97ca4a)",
    border: "1px solid #fff",
    borderRadius: "8px",
    padding: "3px 20px"
  };

  const ComingSoon = ({ comingSoon }) => {
    if (comingSoon != null) {
      return (
        <>
          <div style={comingSoonStyles}>{comingSoon === "Help" ? "contact@byteanalytics.io" : `${comingSoon} - coming soon`}</div>
        </>
      );
    } else {
      return <></>;
    }
  };

  if (currentScreen === "home" || currentScreen === "backToHome") {
    return (
      <div
        className="mask1"
        style={{
          backgroundPosition: `${position.x / 15 - 100}px ${
            position.y / 15 - 100
          }px`
        }}
        data-aos="fade-down"
      >
        <div>BYTE</div>
      </div>
    );
  } else {
    return <ComingSoon comingSoon={comingSoon} />;
  }
};

export default MousePositionDiv;
